<template>
  <ProfessionalLoginTemplate>
    <div class="loginFrmMain">
      <h4 v-if="newUser">Set your password</h4>
      <h4 v-else>Reset your password</h4>

      <div v-if="newUser">
        <p class="text-muted">
          Please enter a password twice to set your password.
        </p>
        <p class="text-muted">
          Passwords must include a minimum of 8 characters, but no more than 64 characters long.
        </p>
      </div>
      <div v-else>
        <p class="text-muted">
          Please enter a new password twice to reset your current password.
        </p>
        <p class="text-muted">
          Passwords must include a minimum of 8 characters, but no more than 64 characters long.
        </p>
      </div>
      <div class="form">
        <div v-if="errors.length > 0" class="margin-bottom-twenty danger-well">
          <ion-text v-for="error in errors" color="danger" :key="error">
            {{ error }}
          </ion-text>
        </div>
        <form @submit.prevent="checkForm" class="login-form">
          <input class="form-control" v-model="passwordOne" type="password" placeholder="Enter new password" />
          <input class="form-control margin-top-ten" v-model="passwordTwo" type="password" placeholder="Re-enter new password" />
          <button v-if="isLoading" class="prime-button margin-top-twenty">
            <ion-spinner name="lines" class="spinner-small"></ion-spinner>
          </button>
          <button v-else class="prime-button margin-top-twenty" type="submit">
            Reset now
          </button>
          <p class="message" v-if="!newUser">
            Already registered?
            <router-link to="/">login</router-link>
          </p>
        </form>
      </div>
    </div>
  </ProfessionalLoginTemplate>
</template>

<script>
import { send as httpSend } from "@/services/Api";
import ProfessionalLoginTemplate from "@/components/Login/Templates/TemplateLoginProfessional";

export default {
  name: "FormLoginPasswordReset",
  components: {
    ProfessionalLoginTemplate
  },
  data() {
    return {
      passwordOne: "",
      passwordTwo: "",
      isLoading: false,
      errors: [],
      newUser: this.$route.query.newUser && this.$route.query.newUser == "y"
    };
  },
  methods: {
    submit: async function() {
      this.isLoading = true;
      var body = {
        token: this.$route.query.id,
        newPassword: this.passwordTwo
      };

      const method = "post";
      const path = document.config.setPassword;

      httpSend({ path, method, payload: body, authToken: true })
        .then(response => {
          if (response.data.status == "error") {
            this.errors.push(response.data.message);
            this.isLoading = false;
          } else {
            this.isLoading = false;
            this.$router.push({
              name: "success",
              params: {
                body: this.newUser ? "Your password has been set. You may now login to LumerisEngage." : "Your password has been changed.",
                navlink: '<a href="/">Back to Login</a>'
              }
            });
          }
        })
        .catch(error => {
          this.isLoading = false;
          this.errors.push(error);
          khanSolo.log(error);
        });
    },
    checkForm: function(e) {
      this.errors = [];

      if (!this.passwordTwo) {
        this.errors.push("Please enter a new password.");
      }
      if (!this.passwordOne) {
        this.errors.push("Please re-enter your password.");
      }
      if (this.passwordOne !== this.passwordTwo) {
        this.errors.push("The entered passwords must match.");
      }
      if (!this.errors.length) {
        this.submit();
      }
      e.preventDefault();
    }
  }
};
</script>
