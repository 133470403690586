<template>
  <div>
    <ResetPasswordFormProfessional />
  </div>
</template>
<script>
import ResetPasswordFormProfessional from "@/components/Login/PasswordResetForms/FormPasswordResetProfessional";

export default {
  name: "LoginView",
  components: {
    ResetPasswordFormProfessional
  }
};
</script>
